<template>
	<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}">
		<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculo.estatus!=1}">
			<img v-lazy="$filters.storageMin(vehiculo.ruta)" class="w-100 h-180 rounded-top" :alt="vehiculo.marca" style="object-fit: cover; height: 160px;">
			<div v-if="vehiculo.idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
				Oferta realizada
			</div>
			<div v-if="showEstatusDf">
				<div v-if="vehiculo.estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
					Disponible
				</div>
				<div v-if="vehiculo.estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
					Apartado
				</div>
				<div v-if="vehiculo.estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
					Vendido
				</div>
			</div>

			<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
				{{$filters.currency(vehiculo.precio)}}
			</div>
		</div>
		<div class="rounded-bottom bg-primary text-white py-2 px-3">
			<div class="text-truncate ">
				<span class="font-weight-bold">{{vehiculo.marca}} {{vehiculo.modelo}}</span>
			</div>
			<div class="d-flex align-items-center justify-content-between">
				<div class="font-small align-items-center d-flex">
					<span class="material-icons align-self-center mr-1">event</span> {{vehiculo.ano}}
				</div>
				<div class="font-small align-items-center d-flex">
					<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculo.numeroPasajeros}}
				</div>
				<div class="font-small align-items-center d-flex justify-content-end">
					{{vehiculo.desTransmision}}
				</div>
			</div>
		</div>
	</router-link>
</template>
<script type="text/javascript">
	export default {
		props: ['data', 'showEstatus'],

		computed: {
			vehiculo(){
				return this.data || {}
			},
			showEstatusDf(){
				if(this.showEstatus != null){
					return this.showEstatus
				}
				return true
			}
		}
	}
</script>